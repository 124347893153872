<template>
  <div style="position: relative; height: 100%">
    <div id="ModelContent"
         class="ModelContent">
      <img class="goBack"
           :src="goBack"
           alt=""
           @click="$router.back()" />
    </div>
  </div>
</template>

<script>
import Api from '@/utils/api.js';

import goBack from '@/assets/img/Icon/goBack.png';

export default {
  data() {
    return {
      goBack,
      data: null,
      viewer: '',
    };
  },
  mounted() {
    this.data = JSON.parse(sessionStorage.getItem('ModelShouData'));
    this.initCesium();

    this.toLoad(this.data);
  },
  methods: {
    initCesium() {
      let that = this;
      // 创建3D地图=====================
      // 使用的Cesium提供的地图和地形数据，就必须申请账号和创建AccessToken
      Cesium.Ion.defaultAccessToken = Api.CesiumToken();
      //镜头默认范围(必须在viewer实例创建前设置)
      // 定位在中国上空
      Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(
        73.0,
        3.0,
        135.0,
        53.0
      );

      that.viewer = new Cesium.Viewer('ModelContent', {
        animation: false, //是否创建动画小器件，左下角仪表
        baseLayerPicker: false, //是否显示图层选择器
        fullscreenButton: false, //是否显示全屏按钮
        geocoder: false, //是否显示geocoder小器件，右上角查询按钮
        homeButton: true, //是否显示Home按钮
        infoBox: false, //是否显示信息框
        sceneModePicker: false, //是否显示3D/2D选择器
        selectionIndicator: false, //是否显示选取指示器组件
        timeline: false, //是否显示时间轴
        navigationHelpButton: false, //是否显示右上角的帮助按钮
        // 加载Cesium自带地图
        // imageryProvider: new Cesium.TileMapServiceImageryProvider({
        //   url: Cesium.buildModuleUrl('Assets/Textures/NaturalEarthII'),
        // }),
      });

      // 全局注册cesium(不能挂载到data里,数据劫持会造成地图卡顿)
      // window.Viewer_figure = viewer;
      // 去掉版权信息
      that.viewer.cesiumWidget.creditContainer.style.display = 'none';
      that.viewer.scene.globe.show = false; //不显示地球，这条和地球透明度选一个就可以

      //天地图卫星影像
      if (!this.$online) {
        // 加载本地地图=======================================
        var arcgis_blue = that.viewer.imageryLayers.addImageryProvider(
          new Cesium.TileMapServiceImageryProvider({
            // 有的版本是用createTileMapServiceImageryProvider
            url: Api.TianDiTuMap(),
            fileExtension: 'png',
          })
        );
      }
      // 开启地形深度检测
      that.viewer.scene.globe.depthTestAgainstTerrain = true;

      // home定位到具体地点和高度(长沙)
      that.viewer.homeButton.viewModel.command.beforeExecute.addEventListener(
        function (e) {
          e.cancel = true;
          //你要飞的位置
          that.viewer.camera.flyTo({
            destination: Cesium.Cartesian3.fromDegrees(
              that.$Center[0],
              that.$Center[1],
              //   112.978159,
              //   28.1608,
              50000
            ),
          });
        }
      );
    },
    toLoad(row) {
      console.log('mode detail page');
      let viewer = this.viewer;

      // 加载3DTileset===================================
      var tileset = viewer.scene.primitives.add(
        new Cesium.Cesium3DTileset({
          url: row.path,
          maximumScreenSpaceError: 5, //最大的屏幕空间误差
          //   maximumNumberOfLoadedTiles: 1000000000000000, //最大加载瓦片个数
        })
      );
      viewer.scene.primitives.add(tileset);
      tileset.readyPromise.then(function (tileset) {
        viewer.scene.primitives.add(tileset);
        var heightOffset = -10.0; //高度
        var boundingSphere = tileset.boundingSphere;
        var cartographic = Cesium.Cartographic.fromCartesian(
          boundingSphere.center
        );
        var surface = Cesium.Cartesian3.fromRadians(
          cartographic.longitude,
          cartographic.latitude,
          0.0
        );
        var offset = Cesium.Cartesian3.fromRadians(
          cartographic.longitude,
          cartographic.latitude,
          heightOffset
        );
        var translation = Cesium.Cartesian3.subtract(
          offset,
          surface,
          new Cesium.Cartesian3()
        );
        tileset.modelMatrix = Cesium.Matrix4.fromTranslation(translation);
        viewer.zoomTo(
          tileset,
          new Cesium.HeadingPitchRange(
            2.5,
            -0.5,
            tileset.boundingSphere.radius * 1
          )
        );
      });
    },
  },
  beforeDestroy() {
    if (this.viewer != '') {
      this.viewer.destroy();
      this.viewer = '';
    }
  },
};
</script>

<style scoped lang="scss">
#ModelContent {
  width: 100%;
  height: 100%;
  background-color: #ffc5c5;
  position: absolute;
  .goBack {
    z-index: 100000;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 10px;
    left: 20px;
    cursor: pointer;
  }
}
</style>
